import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import Navigation from './components/Nav/Navigation';
import Footer from './components/Footer/Footer';

function App() {
  const { t } = useTranslation();

  const animalesUrl = t('animales');
  const lugaresUrl = t('lugares');

  const Home = lazy(() => import('./pages/HomeInicio/Home'));
  const Proyectos = lazy(() => import('./pages/Proyectos/Proyectos'));

  const Tienda = lazy(() => import('./pages/Tienda/Tienda'));
  const SideBar = lazy(() => import('./components/SideBarFix/SideBar'));
  const ProdutoCartasEsp = lazy(() => import('./pages/Tienda/ProdutoCartasEsp'));
  const ProdutoCartasIng = lazy(() => import('./pages/Tienda/ProdutoCartasIng'));
  const InicioRapido = lazy(() => import('./pages/Juego/InicioRapido/InicioRapido'));
  const FAQ = lazy(() => import('./pages/Juego/FAQ/FAQ'));

  const Rana = lazy(() => import('./pages/Animales/RanaCalzonuda'));
  const Mono = lazy(() => import('./pages/Animales/Mono'));
  const Cusuco = lazy(() => import('./pages/Animales/Cusuco'));
  const Pizote = lazy(() => import('./pages/Animales/Pizote'));
  const Iguana = lazy(() => import('./pages/Animales/Iguana'));
  const Tepezcuintle = lazy(() => import('./pages/Animales/Tepezcuintle'));
  const Perezoso = lazy(() => import('./pages/Animales/Perezoso'));
  const Venado = lazy(() => import('./pages/Animales/Venado'));
  const Jaguar = lazy(() => import('./pages/Animales/Jaguar'));
  const Danta = lazy(() => import('./pages/Animales/Danta'));

  const ManuelAntonio = lazy(() => import('./pages/Lugares/ManuelAntonio'));
  const Turrialba = lazy(() => import('./pages/Lugares/Turrialba'));
  const Chirripo = lazy(() => import('./pages/Lugares/Chirripo'));
  const Manzanillo = lazy(() => import('./pages/Lugares/Manzanillo'));
  const Arenal = lazy(() => import('./pages/Lugares/Arenal'));
  const Celeste = lazy(() => import('./pages/Lugares/Celeste'));
  const Islita = lazy(() => import('./pages/Lugares/Islita'));

  return (
    <div className="App">
      <Suspense>
        <Navigation />

        <Routes>
          {/** Botones Generales */}
          <Route path={t('es.homeLink') && t('en.homeLink')} element={<Home />} />
          <Route path={t('navBar.proyectosLink')} element={<Proyectos />} />
          <Route path={t('navBar.tiendaLink')} element={<Tienda />} />
          <Route path={t('tienda.productoUrlEsp')} element={<ProdutoCartasEsp />} />
          <Route path={t('tienda.productoUrlIng')} element={<ProdutoCartasIng />} />
          <Route path='*' element={<Home />} />

          {/** Drop Down de Juego */}
          <Route path={t('navBar.linkGame')} element={<InicioRapido />} />
          <Route path={t('navBar.faqLink')} element={<FAQ />} />


          {/** Drop Down de Animales */}
          <Route path={animalesUrl[0].url} element={<Rana />} />
          <Route path={animalesUrl[1].url} element={<Mono />} />
          <Route path={animalesUrl[2].url} element={<Cusuco />} />
          <Route path={animalesUrl[3].url} element={<Pizote />} />
          <Route path={animalesUrl[4].url} element={<Iguana />} />
          <Route path={animalesUrl[5].url} element={<Tepezcuintle />} />
          <Route path={animalesUrl[6].url} element={<Perezoso />} />
          <Route path={animalesUrl[7].url} element={<Venado />} />
          <Route path={animalesUrl[8].url} element={<Jaguar />} />
          <Route path={animalesUrl[9].url} element={<Danta />} />

          {/** Drop Down de Lugares */}

          <Route path={lugaresUrl[0].url} element={<ManuelAntonio />} />
          <Route path={lugaresUrl[1].url} element={<Turrialba />} />
          <Route path={lugaresUrl[2].url} element={<Chirripo />} />
          <Route path={lugaresUrl[3].url} element={<Manzanillo />} />
          <Route path={lugaresUrl[4].url} element={<Arenal />} />
          <Route path={lugaresUrl[5].url} element={<Celeste />} />
          <Route path={lugaresUrl[6].url} element={<Islita />} />

        </Routes>

        <SideBar />
        <Footer />
        
      </Suspense>
    </div>
  );
}

export default App;
