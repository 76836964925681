
import React, { useEffect, useState } from 'react';
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import SwitchTran from '../TranslationSwitch/SwitchTran';
import { useTranslation } from "react-i18next";
import "./Nav.css";


export default function Navigation() {
  const { t } = useTranslation();
  const animalesUrl = t('animales');
  const lugaresUrl = t('lugares');
  let delayTimer;

  const [expand, setExpand] = useState('sm');
  const [showDropdownJuego, setShowDropdownJuego] = useState(false);
  const [showDropdownAnimales, setShowDropdownAnimales] = useState(false);
  const [showDropdownLugares, setShowDropdownLugares] = useState(false);

  const handleMouseEnter = (dropdown) => {
    switch (dropdown) {
      case 'juego':
        clearTimeout(delayTimer);
        setShowDropdownJuego(true);
        setShowDropdownAnimales(false);
        setShowDropdownLugares(false);
        break;
      case 'animales':
        clearTimeout(delayTimer);
        setShowDropdownJuego(false);
        setShowDropdownAnimales(true);
        setShowDropdownLugares(false);
        break;
      case 'lugares':
        clearTimeout(delayTimer);
        setShowDropdownJuego(false);
        setShowDropdownAnimales(false);
        setShowDropdownLugares(true);
        break;
      default:
        clearTimeout(delayTimer);
        setShowDropdownJuego(false);
        setShowDropdownAnimales(false);
        setShowDropdownLugares(false);
    }
  };

  const handleMouseLeave = () => {
    delayTimer = setTimeout(() => {
      setShowDropdownJuego(false);
      setShowDropdownAnimales(false);
      setShowDropdownLugares(false);
    }, 350);
  };


  useEffect(() => {
    const handleResize = () => {
      const isXxlScreen = window.innerWidth >= 767;
      setExpand(isXxlScreen ? 'sm' : 'xxl');
    };

    // Establecer el valor inicial
    handleResize();

    // Suscribirse al evento de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);

    // Limpiar la suscripción al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className='Navbar'>
      <Container>
        <Navbar expand={expand}>
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={'/img/logo 400x200px-8.webp'} alt={'Inicio'} width="100"
                height="50" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={'/img/logo 400x200px-8.webp'} alt={''} width="100"
                    height="50" />
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body className={`NavBody${expand === 'xxl' ? ' justify-content-center' : ''}`}>
                <Nav>
                  <Nav.Link href={t('navBar.homeLink')}>{t('navBar.inicio')}</Nav.Link>
                  <Nav.Link href={t('navBar.proyectosLink')}>{t('navBar.proyectos')}</Nav.Link>

                  <NavDropdown show={showDropdownJuego}
                    onMouseEnter={() => handleMouseEnter('juego')}
                    onMouseLeave={handleMouseLeave}
                    className='NavDropdown'
                    title={t('navBar.juego')}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}>

                    <NavDropdown.Item href={t("navBar.linkGame")}>{t('navBar.incioRapido')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href="https://www.docs.estampidacr.com">{t('navBar.documentacion')}</NavDropdown.Item>
                    <NavDropdown.Divider />


                    <NavDropdown.Item href={t('navBar.faqLink')}>{t('navBar.faq')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href="https://www.youtube.com/channel/UCeWAkTsZOt658NhyLFXAwPQ">{t('navBar.videosTutorial')}</NavDropdown.Item>

                  </NavDropdown>

                  <NavDropdown show={showDropdownAnimales}
                    onMouseEnter={() => handleMouseEnter('animales')}
                    onMouseLeave={handleMouseLeave}
                    className='NavDropdown'
                    title={t('navBar.animales')}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}>

                    <NavDropdown.Item href={animalesUrl[0].url}>{t('navBar.rana')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[1].url}>{t('navBar.monoCariblanco')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[2].url}>{t('navBar.cusuco')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[3].url}>{t('navBar.pizote')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[4].url}>{t('navBar.iguana')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[5].url}>{t('navBar.tepezcuintle')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[6].url}>{t('navBar.perezoso')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[7].url}>{t('navBar.vendoColaBlanca')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[8].url}>{t('navBar.jaguar')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={animalesUrl[9].url}>{t('navBar.danta')}</NavDropdown.Item>

                  </NavDropdown>
                  <NavDropdown show={showDropdownLugares}
                    onMouseEnter={() => handleMouseEnter('lugares')}
                    onMouseLeave={handleMouseLeave}
                    className='NavDropdown'
                    title={t('navBar.lugares')}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}>

                    <NavDropdown.Item href={lugaresUrl[0].url}>{t('navBar.manuelAntonio')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={lugaresUrl[1].url}>{t('navBar.volcanTurrialba')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={lugaresUrl[2].url}>{t('navBar.cerroChirripo')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={lugaresUrl[3].url}>{t('navBar.manzanillo')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={lugaresUrl[4].url}>{t('navBar.volcanArenal')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={lugaresUrl[5].url}>{t('navBar.rioCeleste')}</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href={lugaresUrl[6].url}>{t('navBar.puntaIslita')}</NavDropdown.Item>

                  </NavDropdown>

                  <Nav.Link href={t('navBar.tiendaLink')}>{t('navBar.tienda')}</Nav.Link>

                </Nav>
                <SwitchTran />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <Outlet />
      </Container>
    </div>
  );
}
