import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap';
import './Share.css';

const ShareIcons = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    // Lógica para copiar el correo electrónico al portapapeles
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000); // Después de 3 segundos, el mensaje de "Correo copiado" desaparecerá
  };

  return (
    <div>
      <div className="share-icon-collections d-flex align-items-center">
        {/* Facebook */}
        <a href={t("redesSociales.facebookUrl")} target="_blank" rel="noreferrer" className="mr-2">
          <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" style={{ width: 30 }} viewBox="0 0 512 512" aria-label="fb" role="img">
            <path d="m375.14,288l14.22,-92.66l-88.91,0l0,-60.13c0,-25.35 12.42,-50.06 52.24,-50.06l40.42,0l0,-78.89s-36.68,-6.26 -71.75,-6.26c-73.22,0 -121.08,44.38 -121.08,124.72l0,70.62l-81.39,0l0,92.66l81.39,0l0,224l100.17,0l0,-224l74.69,0z"></path>
          </svg>
        </a>

        {/* Instagram */}
        <a href={t("redesSociales.instagramUrl")} target="_blank" rel="noreferrer" className="mr-2">
          <img className="share-icon" style={{ width: 20 }} src="/svg/instagram-icon.svg" alt="Instagram Icon" />
        </a>

        {/* Email */}
        <div onClick={handleClick}>
          <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" style={{ width: 30 }} viewBox="0 0 512 512" aria-label="mail" role="img">
            <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
          </svg>
        </div>
      </div>

      <div className="row mt-3 alertSeccion">
        <div className="col">
          {copied && <Alert variant="success">{t('alertas.correo')}</Alert>}
        </div>
      </div>
    </div>
  );
}

export default ShareIcons;
