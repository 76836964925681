import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Fade } from "react-awesome-reveal";
import Share from '../Share/Share.tsx'
import { useTranslation } from "react-i18next";
import './Footer.css'
import Button from 'react-bootstrap/Button';

function obtenerAnioActual() {
  return new Date().getFullYear();
}

export default function Footer() {
  const { t } = useTranslation();
  const anioActual = obtenerAnioActual();
  return (
    <Fade top distance='10%'>
      <div className='footer-header'>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col sm={9} className="d-flex align-items-center">
              <p className="text-center m-0">
                {t('footer.textoCabecera')}
              </p>
            </Col>
            <Col sm={3} className="d-flex justify-content-end">
              <Button href={t('navBar.tiendaLink')} className='footer-button' active>
                {t('footer.botton')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className='footer'>

        <Container className='footerContainer footer'>
          <Row>
            {/* Secciones */}
            <Col className='text-center py-3' md={4}>
              <img src={'/img/Estampida-Profile-Picture-20211230-1.webp'} alt={''}/>
              <p className='mt-4 text-secondary text-center'>{t('footer.col1-PrimerTexto')}</p>
              <p className='mt-2 text-center'>{t('footer.col1-SegundoTexto')}</p>
            </Col>
            <Col className='midCol py-3' md={4}>
              <ul>
                <h6>{t('footer.col2-titulo')}</h6>
                <br />
                <li><svg xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                </svg>
                  {t('footer.organizacion')}
                </li>

                <li><svg xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
                  {t('footer.ubicacion')}
                </li>

                <li><svg xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                </svg>
                  {t('footer.telefono')}
                </li>

                <li><svg xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>
                  {t('footer.correo')}
                </li>

                <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM6.262 6.072a8.25 8.25 0 1 0 10.562-.766 4.5 4.5 0 0 1-1.318 1.357L14.25 7.5l.165.33a.809.809 0 0 1-1.086 1.085l-.604-.302a1.125 1.125 0 0 0-1.298.21l-.132.131c-.439.44-.439 1.152 0 1.591l.296.296c.256.257.622.374.98.314l1.17-.195c.323-.054.654.036.905.245l1.33 1.108c.32.267.46.694.358 1.1a8.7 8.7 0 0 1-2.288 4.04l-.723.724a1.125 1.125 0 0 1-1.298.21l-.153-.076a1.125 1.125 0 0 1-.622-1.006v-1.089c0-.298-.119-.585-.33-.796l-1.347-1.347a1.125 1.125 0 0 1-.21-1.298L9.75 12l-1.64-1.64a6 6 0 0 1-1.676-3.257l-.172-1.03Z" clipRule="evenodd" />
                </svg>
                  {t('footer.sitioWeb')}
                </li>

              </ul>
            </Col >
            <Col className='d-flex align-items-center justify-content-center py-3' md={4}>
              <Share description={"this is a basic share page"} />
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              <p>&copy;{anioActual}{t('footer.copyRigth')}</p>
            </Col>
            <Col className='text-end'>
            </Col>
          </Row>
        </Container>

      </footer>
    </Fade>
  );
};

