import React from 'react';
import './SwitchTran.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Col, Dropdown } from 'react-bootstrap';
import urls from '../../i18n/lenguages/urls.json';

export default function SwitchTran() {
    const { i18n } = useTranslation('global');
    const location = useLocation();

    const changeLanguage = (language) => {
        const currentLanguage = i18n.language.split('-')[0];
        const currentRoutes = urls[currentLanguage].routes;
        // Obtener la clave de la ruta actual
        const currentRouteKey = Object.keys(currentRoutes).find(
            key => currentRoutes[key] === location.pathname
        );
        // Obtener la nueva ruta en el idioma deseado
        const newUrl = currentRouteKey ? urls[language].routes[currentRouteKey] : `/${language}/`;
        // Cambiar el idioma y actualizar la URL
        i18n.changeLanguage(language);
        window.location.href = newUrl;
    };

    return (
        <Col md={2} className='px-2 d-flex justify-content-end'>
            <Dropdown className='Switch'>
                <Dropdown.Toggle id="dropdown-basic">
                    {i18n.language.split('-')[0].toUpperCase()}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ minWidth: 'auto' }}>
                    <Dropdown.Item onClick={() => changeLanguage('es')}>ES</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
                </Dropdown.Menu >
            </Dropdown>
        </Col>
    );
};